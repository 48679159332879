import { Grommet, Box, Heading, Image } from 'grommet';
import { useEffect, useState } from 'react';

const theme = {
  "name": "my theme",
  "rounding": 4,
  "spacing": 24,
  "defaultMode": "light",
  "global": {
    "colors": {
      "brand": {
        "dark": "#7700cc",
        "light": "#6600cc"
      },
      "background": {
        "dark": "#111111",
        "light": "#FFFFFF"
      },
      "background-back": {
        "dark": "#111111",
        "light": "#EEEEEE"
      },
      "background-front": {
        "dark": "#222222",
        "light": "#FFFFFF"
      },
      "background-contrast": {
        "dark": "#FFFFFF11",
        "light": "#11111111"
      },
      "text": {
        "dark": "#EEEEEE",
        "light": "#333333"
      },
      "text-strong": {
        "dark": "#FFFFFF",
        "light": "#000000"
      },
      "text-weak": {
        "dark": "#CCCCCC",
        "light": "#444444"
      },
      "text-xweak": {
        "dark": "#999999",
        "light": "#666666"
      },
      "border": {
        "dark": "#444444",
        "light": "#CCCCCC"
      },
      "control": "brand",
      "active-background": "background-contrast",
      "active-text": "text-strong",
      "selected-background": "brand",
      "selected-text": "text-strong",
      "status-critical": "#FF4040",
      "status-warning": "#FFAA15",
      "status-ok": "#00C781",
      "status-unknown": "#CCCCCC",
      "status-disabled": "#CCCCCC",
      "graph-0": "brand",
      "graph-1": "status-warning"
    },
    "font": {
      "family": "Helvetica"
    },
    "active": {
      "background": "active-background",
      "color": "active-text"
    },
    "hover": {
      "background": "active-background",
      "color": "active-text"
    },
    "selected": {
      "background": "selected-background",
      "color": "selected-text"
    }
  },
  "chart": {},
  "diagram": {
    "line": {}
  },
  "meter": {}
}

function App() {
  const [foods, setFoods] = useState({
    breakfast: ["", {}],
    lunch: ["", {}],
    dinner: ["", {}],
  })
  useEffect(() => {
    fetch("https://cuisine_explorer.leoorshansky.workers.dev/foods").then(res => res.json()).then(j => setFoods(j))
  }, [])

  return (
    <Grommet theme={theme}>
      <Box direction="row" justify="evenly">
        <Box direction="column" width="100%" background={{"color": "accent-1", opacity: "medium"}}>
          <Box direction="column" border={{
            "color": "neutral-3",
            "style": "solid",
            "size": "large"
          }} align="center">
            <Heading level="2">Breakfast</Heading>
          </Box>
          { foods.breakfast[0] === "none"
            ? <Heading alignSelf="center" level="4" textAlign="center">No region is currently eating it!</Heading>
            : <Heading alignSelf="center" level="4" textAlign="center">In {foods.breakfast[0]} right now, you might find {foods.breakfast[1].name} for breakfast</Heading>
          }
          <Image src={foods.breakfast[1].image}></Image>
        </Box>
        <Box direction="column" width="100%" background={{"color": "accent-4", opacity: "medium"}}>
          <Box direction="column" border={{
            "color": "neutral-3",
            "style": "solid",
            "size": "large"
          }} align="center">
            <Heading level="2">Lunch</Heading>
          </Box>
          { foods.lunch[0] === "none"
            ? <Heading alignSelf="center" level="4" textAlign="center">No region is currently eating it!</Heading>
            : <Heading alignSelf="center" level="4" textAlign="center">In {foods.lunch[0]} right now, you might find {foods.lunch[1].name} for lunch</Heading>
          }
          <Image src={foods.lunch[1].image}></Image>
        </Box>
        <Box direction="column" width="100%" background={{"color": "neutral-4", opacity: "medium"}}>
          <Box direction="column" border={{
            "color": "neutral-3",
            "style": "solid",
            "size": "large"
          }} align="center">
            <Heading level="2">Dinner</Heading>
          </Box>
          { foods.dinner[0] === "none"
            ? <Heading alignSelf="center" level="4" textAlign="center">No region is currently eating it!</Heading>
            : <Heading alignSelf="center" level="4" textAlign="center">In {foods.dinner[0]} right now, you might find {foods.dinner[1].name} for dinner</Heading>
          }
          <Image src={foods.dinner[1].image}></Image>
        </Box>
      </Box>
    </Grommet>
  );
}

export default App;
